<template>


  <div class="pros" v-if="shorts_data?.items_shorts?.length">

    <h2 class="divider-sec">
      {{ props.title }} 
      <nuxt-link class="h2_show_all" :to="'/shorts?tags=' + props.tag">
        {{ props.showmore }} 
        <!-- ({{ cnt }}) -->
      </nuxt-link>
    </h2>



    <HorizontalScroll
      :arrows="true"
      :arrows-show-hover="true"
      :platform="'mobile'"
      >
      <GeneralShowMoreFeed 
        :platform="'desktop'"
        :max-height="450"
        > 

        <div 
        v-if="shorts_data?.items_shorts?.length"
        class="videolist">
          <GeneralVideoShortLink v-for="(opt, ind) in shorts_data.items_shorts" 
            :key="ind"
            :cover="opt.size_m"
            :name="opt.item_diary.name"
            :faza="opt.item_week.faza"
            :week="opt.item_week.days"
            :link="'/shorts/'+ opt.video_id"
            :video-id="opt.video_id"
            @click="useRouter().push('/shorts/'+ opt.video_id)"
            />
        </div>
  
      </GeneralShowMoreFeed>

  

    </HorizontalScroll>
    
  </div>

</template>

<script setup>


const {$api, $adv, $tagsUtil, $ga, $patcher} = useNuxtApp()
const { t } = useI18n()
 
const props = defineProps({
  tag: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  showmore: {
    type: String,
    default: ''
  },
  link: {
    type: String,
    default: ''
  },
})


const start = ref(0)
const limit = ref(10)
const sort = ref('new')
 

const loadData = async function() {   
 
 const response = await $api.getShorts({
   start: start.value,
   limit: limit.value,
   sortable: sort.value,
   q: '',
   tags: [props.tag],
 })  

 return response;  
}


const { pending: is_loading,  data: shorts_data } = await useLazyAsyncData('shorts_data', async () => await loadData())


</script>



<style scoped>

/* videolist */

.videolist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  justify-content: left;
  gap: 1rem;
  transition: all 0.3s ease;  
}

@container pb (max-width: 1540px) {
  .videolist {    
    max-height: 364px;
  }
}

@container pb (max-width: 1340px) {
  .videolist {
    max-height: 364px;
  }
}

@container pb (max-width: 1140px) {
  .videolist {
    max-height: 364px;
  }
}

@container pb (max-width: 950px) {
  .videolist {
    max-height: 364px;
    
  }
}

@container pb (max-width: 750px) {
  .videolist {
    max-height: 274px;
    
  }
}

@container pb (max-width: 550px) {
  .videolist {
    max-height: 364px;
    
  }
}

@container pb (max-width: 500px) {
  .videolist {
    flex-wrap: nowrap;
  }
}


.show.is-more .videolist {
  max-height: 4000px;
}

</style>
